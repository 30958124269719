.header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #305123;
  padding: 0.5em 2em;
  width: 100%;
  gap: 2em;
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.testSystemHeader {
  background-color: #95190c;
}

.testsystemtext {
  color: white;
  font-size: large;
  font-weight: bold;
}

.headertext {
  color: white;
  display: flex;
  align-items: center;
}

.logoutIcon {
  margin-left: 0.5em;
}

@media (max-width: 600px) {
  .header {
    gap: 1.2em;
    padding: 0.5em 1.2em;
  }

  .testsystemtext {
    color: white;
    font-size: small;
    margin-top: 0.3em;
  }

  .headertext {
    font-size: small;
    margin-top: 0.3em;
  }

  .username {
    display: none !important;
  }
}
