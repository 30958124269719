@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --fleetpoint-green: rgb(48, 81, 35);
  --gray: #dcdcdc;
  --light-gray: #f0f0f0;
  --white: white;
  --black: black;

  --primary-color: var(--fleetpoint-green);
  --background-color: var(--white);
  --negativ-color: var(--white);

  --text-color: var(--black);

  --grouping-color: var(--light-gray);

  --border-radius: 6px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
}

textarea {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

.button {
  background-color: var(--primary-color);
  border: solid 2px var(--primary-color);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  color: var(--negativ-color);
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 12px 25px;
  text-decoration: none;
  text-align: center;
  transition: all 200ms;
}

.button.secondary {
  border: solid 2px var(--primary-color);
  color: var(--primary-color);
  background-color: var(--background-color);
}

.button.small {
  padding: 6px 10px;
  padding: 12px 12px;
}

.button.wide {
  margin-top: 1em;
  width: 100%;
  padding: 3px 3px;
  padding: 6px 6px;
}

.buttonContent {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.icon-btn {
  color: var(--primary-color);
}

.row {
  display: flex;
  gap: 1em;
}
.row.center {
  align-items: center;
}
.row.space {
  justify-content: space-between;
}

* {
  box-sizing: border-box;
}

section {
  padding-bottom: 2em;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

input[type='number'],
input[type='text'] {
  height: 1.8em;
}

select {
  height: 1.8em;
}

@media (max-width: 768px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1em;
  }

  button {
    padding-inline: 1em;
  }
}

.header_header__sI8YR {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #305123;
  padding: 0.5em 2em;
  width: 100%;
  gap: 2em;
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.header_testSystemHeader__GYmxF {
  background-color: #95190c;
}

.header_testsystemtext__KEHwe {
  color: white;
  font-size: large;
  font-weight: bold;
}

.header_headertext__hP2kf {
  color: white;
  display: flex;
  align-items: center;
}

.header_logoutIcon__e88zw {
  margin-left: 0.5em;
}

@media (max-width: 600px) {
  .header_header__sI8YR {
    gap: 1.2em;
    padding: 0.5em 1.2em;
  }

  .header_testsystemtext__KEHwe {
    color: white;
    font-size: small;
    margin-top: 0.3em;
  }

  .header_headertext__hP2kf {
    font-size: small;
    margin-top: 0.3em;
  }

  .header_username__H1spS {
    display: none !important;
  }
}

.page_pageMain__ErG9Q {
  grid-area: Content;
  padding: 2em;
  max-width: 100vw;
}

.react-responsive-modal-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.react-responsive-modal-container {
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}

/* Used to trick the browser to center the modal content properly  */
.react-responsive-modal-containerCenter:after {
  width: 0;
  height: 100%;
  content: '';
  display: inline-block;
  vertical-align: middle;
}

.react-responsive-modal-modal {
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}

/* Used to fix a screen glitch issues with the animation see https://github.com/pradel/react-responsive-modal/issues/495 */
.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal {
  animation-fill-mode: forwards !important;
}

@keyframes react-responsive-modal-overlay-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes react-responsive-modal-overlay-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes react-responsive-modal-modal-in {
  0% {
    transform: scale(0.96);
    opacity: 0;
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

@keyframes react-responsive-modal-modal-out {
  0% {
    transform: scale(100%);
    opacity: 1;
  }
  100% {
    transform: scale(0.96);
    opacity: 0;
  }
}

